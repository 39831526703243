import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import React from 'react'
import Layout from "../components/Layout"
import Sidebar from "../components/Sidebar"

export default function blog({ data }) {
    const posts = data.posts.nodes;
    return (
      <Layout>
      <Helmet>
        <title>Blog | Use Less Divs</title>

        <meta name="description" content="A blog about html, css, javascript, focusing on accessibility" />
        <meta property="og:title" content="Blog | Use Less Divs" />
        <meta property="og:description" content="A blog about html, css, javascript, focusing on accessibility" />
        <meta property="og:image" content="https://uselessdivs.com/img/og-img.png" />
        <meta property="og:image:alt" content="Use Less Divs" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://uselessdivs.com/privacy/" />
        <link rel="canonical" href="https://uselessdivs.com/privacy/" />
      </Helmet>
        <main>
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <h1 className="sr-only">
                  You are on the blog page where you can fint all the blog posts
                </h1>
                <h2 className="h2">Blog</h2>
                <div className="row">
                {posts.map(post => (
                    <div className="col-md-6">
                        <article className="article article--small" key={post.id}>
                            <Link className="text-decoration-none" to={"/blog/" + post.frontmatter.slug} aria-label={"Read more about " + post.frontmatter.title} >
                            <h3 className="article__title">
                              <span>
                                { post.frontmatter.title }
                              </span>
                            </h3>
                            </Link>
                            <time className="article__date">{ post.frontmatter.dateCreated }</time>
                            <p>
                            { post.frontmatter.lead }
                            </p>
                        </article>
                    </div>
                ))}
                </div>
              </div>
            <Sidebar />
            </div>
          </div>
        </main>
      </Layout>
    )
}

// export page query
export const query = graphql`
query MyQuery2 {
  posts: allMdx(sort: {fields: frontmatter___dateCreated, order: DESC}) {
    nodes {
      frontmatter {
        lead
        slug
        title
        dateCreated(formatString: "DD. MMMM YYYY.")
      }
      id
    }
  }
}
`